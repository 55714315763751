import React from "react"
import { Link, graphql } from "gatsby"

import { Container } from "nes-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import { rhythm } from "../utils/typography"
import YouTube from "react-youtube"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    //const posts = data.allMarkdownRemark.edges
    const videos = data.allYoutubeVideo.edges.reverse()

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All casts" />
        
        {videos.map(({ node }) => {
          const title = node.title
          return (
            <Container
              dark={false}
              rounded={true}
              title={title}
              centered={true}
              style={{ marginTop: `10px`, marginBottom: `30px` }}
            >
              <p>{node.description}</p>
              <YouTube
                videoId={node.videoId}
                />
              <p>{node.publishedAt}</p>
            </Container>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allYoutubeVideo(
      filter: { title: { regex: "/[pP]o(d?)cast/" } }
      sort: { fields: publishedAt, order: ASC }
    ) {
      edges {
        node {
          publishedAt(formatString: "dddd, MMMM Do YYYY, h:mm:ss a z")
          description
          title
          videoId
          localThumbnail {
            publicURL
          }
        }
      }
    }
  }
`
/*

 allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    
{posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return [
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>,
            <Container
              dark={false}
              rounded={true}
              title={node.frontmatter.title || node.fields.slug}
              centered={true}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </Container>,
          ]
        })} */